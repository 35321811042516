import { Home } from "./home/Home";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

function App() {
  const router = createBrowserRouter([
    {
      path: "/asd6815fr5gf16981654af65r",
      element: <Home />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
