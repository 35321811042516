import "./detail.scss";

export const Detail = ({title, desc}) => {
  return (
    <div className="detail">
      <div className="header">{title}</div>
      <div className="desc">{desc}</div>
    </div>
  );
};
