import { Detail } from "../components/detail/Detail";
import "./home.scss";

export const Home = () => {
  return (
    <div className="home">
      <h1>COM-001</h1>
      <p>User Info</p>
      <Detail title="PIC" desc="Rika" />
      <Detail title="Department" desc="Quality" />
      <p>Specifications</p>
      <Detail title="Mainboard" desc="ASROCK A520M-HDV" />
      <Detail title="Processor" desc="AMD Ryzen 3 4100" />
      <Detail title="Memory" desc="16 GB" />
      <Detail title="SSD" desc="128 GB" />
      <Detail title="HDD" desc="1 TB" />
      <p>System And Applications</p>
      <Detail title="Operating System" desc="Windows 11 Pro" />
      <Detail title="Office" desc="Office 365" />
      <Detail title="Antivirus" desc="Yes, Norton Internet Security" />
      <p>Connectivity</p>
      <Detail title="Internet" desc="Yes" />
      <Detail title="Email" desc="Yes" />
      <p>Others</p>
      <Detail title="Note" desc="-" />
    </div>
  );
};
